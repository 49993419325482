<template>
  <div class="faq-container-wrap">
    <div class="faq-container">
      <h2>Часто задаваемые вопросы</h2>
      <ul>
        <li>
          <button @click="show1 = !show1">
            <span>Где производятся Компакт-диски <b>Recassist</b> ?</span>
          </button>
          <transition name="fade">
            <p v-if="show1">
              Компакт-диски производятся на предприятии в ОАЭ. Завод построен по лицензии Японской корпорации Taiyo Yuden и имеет все сертификаты на стандарты хранения данных ECMA 396, ECMA 379.
            </p>
          </transition>
        </li>
        <li>
          <button @click="show2 = !show2">
            <span>Как понять, что передо мной не подделка?</span>
          </button>
          <transition name="fade">
            <p v-if="show2">
              Компакт-диски можно проверить с помошью программы, которая читает 0-ю технологическую дорожку с кодами матрицы. 
              Код дисков для MEDICINE LINE соотв. TDK TTH02, для PROFI LINE это TDK TTG-02.
            </p>
          </transition>
        </li>
        <li>
          <button @click="show3 = !show3">
            <span>Сколько времени диски могут хранить данные?</span>
          </button>
          <transition name="fade">
            <p v-if="show3">
              CD-r и DVD-r имеют сертификацию по ECMA-396, это 30 лет хранения при обычном офисном хранении. При бережном хранении срок сохранения данных может достигать 50 лет.
              Для линейки GOLD ARCHIVE LINE по стандарту ECMA-379 может достигать 100 лет, из-за использования высоко-технологических материалов.
            </p>
          </transition>
        </li>
        <li>
          <button @click="show4 = !show4">
            <span>Какие технологии печати доступны для компакт-дисков <b>Recassist</b> ?</span>
          </button>
          <transition name="fade">
            <p v-if="show4">
              Компакт-диски поставляются с несколькими поверхностями. И выбираются в зависимости от той технолгии печати, которая будет применяться.<br/>
              1. Диски для Струйной печати. На этих дисках нанесен специальный слой с белой подложкой и они подходят для печати на бытовых струйных принтерах. <br/>
              2. Диски для термопечати для специальных термо-печатающих устройств.<br/>
              3. Диски для Офсетной и Шелкотрафаретной печати. Это промышленная технология доступная только для крупных производств.<br/>
            </p>
          </transition>
        </li>
        <!-- <li>
          <button @click="show5 = !show5">
            <span>Item5</span>
          </button>
          <transition name="fade">
            <p v-if="show5">
              Loren ipsum Loren ipsum Loren ipsum Loren ipsum Loren ipsum Loren
              ipsum Loren ipsum Loren ipsum Loren ipsum
            </p>
          </transition>
        </li> -->
      </ul>
    </div>
    <div class="req-input-wrap">
      <div class="req-input">
        <div class="input">
          <input type="text" placeholder="enter email for request" />
        </div>
        <div class="button">
          <button>Отправить</button>
        </div>
      </div>
      <div>
        <span>
          Укажите ваш E-mail или телефон. Мы сами свяжемся с вами как можно
          быстрее.
        </span>
      </div>
    </div>
  </div>
</template>



<style lang="scss" scoped>
b {color: red;}
.req-input-wrap {

  .req-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    .input {
      min-width: 600px;

      input {
        padding: 15px 20px;
        width: calc(100% - 42px);
        text-align: left;
        border-color: white;
        background-color: white;
        border-style: solid;
      }
    }
    .button {
      min-width: 200px;

      button {
        display: block;
        font-size: 20px;
        width: 100%;
        padding: 12px 20px;
        color: white;
        background-color: rgb(255, 0, 0);
        cursor: pointer;
        border-color: rgb(255, 0, 0);
      }
    }
  }
  span {
    color: white;
    font-size: 18px;
    text-align: center;
    display: block;
    margin: 0 auto;
  }

  margin-bottom: 30px;
  

}
h2 {
  color: white;
  text-align: center;
  font-size: 55px;
  margin-bottom: 55px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.faq-container-wrap {
  border-bottom: 8px solid #222;
}
.faq-container {
  width: 1280px;
  margin: 0px auto;
  padding: 60px 0;
}

ul {
  width: 800px;
  margin: 20px auto;
  color: white;
  width: 75%;
  margin: 2em auto;
  li {
    list-style: none;
    button {
      display: flex;
      width: 100%;
      margin: 12px 0;
      background-color: #303030;
      padding: 6px 15px;
      border: none;
      text-align: left;
      color: white;
      font-size: 20px;
      justify-content: space-between;
      align-items: center;
      span {
        margin: 10px;
      }
      cursor: pointer;
    }
    button::after {
      content: "+";
      font-size: 40px;
      display: block;
      font-weight: 100;
      color: #626161;
    }
    p {
      margin: 12px 0;
      padding: 6px 15px;
      line-height: 1.5em;
    }
  }
}
</style>



<script>
export default {
  name: "Faqs",
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    };
  },
  components: {},
  methods: {},
};
</script>