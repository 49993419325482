<template>
  <div class="items-container">
    <div class="items-row-wrap">
      <div class="items-row">
        <div class="text-wrap">
          <h2>MEDICINE LINE</h2>
          <hr />
          <p>Компакт-диски медицинского назначения. Подготовлены специально для быстрой записи в МРТ, КТ со сроком жизни информации до 30 лет.</p>
          <a href="medicine_line">ПОДРОБНЕЕ</a>
        </div>
        <div class="img-wrap">
          <div class="img">
            <img src="/images/photos/medicine-line-img.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="items-row-wrap">
      <div class="items-row">
        <div class="text-wrap">
          <h2>PROFESSIONAL LINE</h2>
          <hr />
          <p>Компакт-диски для профессионального использования в различных сферах экономики. 
            Диски подготовлены для струйной или термо-печати. В наличие широкий 
            ассортимент типов дисков для любых пишущих устройств.
          </p>
          <a href="profi_line">ПОДРОБНЕЕ</a>
        </div>
        <div class="img-wrap">
          <div class="img">
            <img src="/images/photos/professional-line-img.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="items-row-wrap">
      <div class="items-row">
        <div class="text-wrap">
          <h2>GOLD ARCHIVE LINE</h2>
          <hr />
          <p>Специализированные диски для долгосрочного хранения информации до 100 лет. Имеют сертификат ECMA-379 на LONG-LIFE Archival Type. 
            Производятся на оборудовании с соблюдением сертифицированных японских технологий. 
            Возможно заказать и доставить за короткий срок.
          </p>
          <a href="goldarchive_line">ПОДРОБНЕЕ</a>
        </div>
        <div class="img-wrap">
          <div class="img">
            <img src="/images/photos/archive-line-img.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<style lang="scss" scoped>
$ml: #52ab62;
$pl: #2e31c7;
$gal: #a56b00;

.items-row-wrap:nth-child(1) {
  hr {
    background-color: $ml;
    border-color: $ml;
  }
  .img-wrap {
    text-align: right;
    img {
      border-color: $ml;
    }
  }
  a {
    background-color: $ml;
  }
}
.items-row-wrap:nth-child(2) {
  hr {
    background-color: $pl;
    border-color: $pl;
  }
  .img-wrap {
    text-align: left;
    order: -1;
    img {
      border-color: $pl;
    }
  }
   a {
    background-color: $pl;
  }
}
.items-row-wrap:nth-child(3) {
  hr {
    background-color: $gal;
    border-color: $gal;
  }
  .img-wrap {
    text-align: right;
    img {
      border-color: $gal;
    }
  }
   a {
    background-color: $gal;
  }
}
a {
  display: inline-block;
  color: white;
  margin-top: 20px;
  border: 3px solid transparent;
  text-decoration: none;
  padding: 20px 30px;
  transition: .4s border;

  &:hover {
    border-color: white;
    
  }

}
.items-container {
  // width: 1280px;
  // width: 100%;
  margin: 0px auto;
}

.items-row-wrap {
 border-bottom: 8px solid #222;
}
.items-row {
  width: 1280px;
  margin: 0px auto;
  padding: 60px 0;
  display: flex;
  flex-direction: row;
 
  & > div {
    width: 50%;
    margin: 20px;
  }

  .text-wrap {
    h2 {
      color: white;
      font-size: 45px;
      font-weight: bolder;
    }
    hr {
      // color: white;
      // background-color: white;
      height: 6px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    p {
      color: white;
      font-size: 1.5em;
    }
  }
  .img-wrap {
    text-align: right;
    img {
      width: 450px;
      height: 300px;
      border: 6px solid;
    }
  }
}
</style>



<script>
export default {
  name: "BrandsList",
  components: {},
  methods: {},
};
</script>