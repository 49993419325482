<template>
  <div class="sellers-container-wrap">
    
    <div class="sellers-container">
      
      <h2>Где купить</h2>
      <p class="note">
        Приобрести и заказать нужные вам компакт-диски CD-r, DVD-r, DVD"+"-r, DVD или CD Inkjet Printable, вы можете в одной из компаний. Каждый из сайтов ниже определяет целевое использование дисков.
        В Типографии CD-Print вы можете заказать диски с печатью и записью, или просто с покраской. А также заказать упаковку для дисков.
        В ОПТ-ДИСК вы можете купить оптовые партии дисков по максимально выгодной цене или заключить контракт на поставки дисков по 44 ФЗ.
        Проект MRT-Disc это специализированный сайт для медицинских диагностических центров оказывающих услуги по проведению МРТ и КТ диагностики.

      </p>
      <div class="items">
        <div>
          <img src="/images/cd-print-logo.svg" alt="" />
          <p>Типография CD-Print</p>
          <a href="http://cdprint.su" target="blanc">cdprint.su</a>
        </div>
        <div>
          <img src="/images/opt-disc-logo.svg" alt="" />
          <p>ОПТ-ДИСК</p>
          <a href="http://opt-disc.ru" target="blanc">opt-disc.ru</a>
        </div>
        <div>
          <img src="/images/mrt-disc-logo.svg" alt="" />
          <p>MRT-Disc</p>
          <a href="http://mrt-disc.ru" target="blanc">mrt-disc.ru</a>
        </div>
      </div>
    </div>
  </div>
</template>



<style lang="scss" scoped>
h2 {
  color: white;
  text-align: center;
  font-size: 55px;
  margin-bottom: 55px;
}
p.note {
  font-size: 26px;
  color: white;
  margin-bottom: 60px;
  line-height: 1.6em;
   text-indent: 1.5em; /* Отступ первой строки */
    text-align: justify; /* Выравнивание по ширине */
}
.sellers-container-wrap {
  border-bottom: 8px solid #222;
}
.sellers-container {
  width: 1280px;
  margin: 0px auto;
  padding: 60px 0;
}

.items {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  div>* {
    margin-bottom: 15px;
  }
  div {
    width: 345px;
    a {
      color: white;
    }
    text-align: center;
    // width: 33%;
    color: white;
    img {
      // width: 100%;
      height: 50px;

    }
    p {
      font-size: 35px;
      font-weight: bold;
    }
  }
}
</style>



<script>
export default {
  name: "Sellers",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>