<template>
  <div class="container-wrap">
     <p><a name="ClientsService"></a></p>
    <div class="container">
     
      <h2>Клиентский сервис</h2>
      <p class="note">
        В отличие от других изготовителей и поставщиков компакт-дисков наш бренд
        оказывает существенный сервис нашим клиентам. Нам важно, чтобы нашу
        продукцию было удобно и безопасно использовать.
      </p>
      <h3>Что получает наш клиент вместе с покупкой продукции <span>Recassist</span>?</h3>
      <ul>
        <li>       
          <span class="icon"> <i class="fas fa-circle"></i></span>
          Гарантия компакт-дисков, вплоть до возврата денег, если вам не подошли
          наши диски. Если ваше оборудование не может удобно и надежно
          записывать их.
        </li>
        <li>
          <span class="icon"> <i class="fas fa-circle"></i></span>
          Удобная доставка. Во многих предложениях Вы получите бесплатную
          доставку, где бы Вы ни находились.
        </li>
        <li>
          <span class="icon"> <i class="fas fa-circle"></i></span>
          Комплектация партии дисков удобными маркетами для их подписания.
          Часто на предприятиях есть необходимость подписать диски. Такие
          маркеры наши клиенты получают бесплатно в каждой партии.
        </li>
        <li>
          <span class="icon"> <i class="fas fa-circle"></i></span>
          Если Вы являетесь нашим постоянным клиентом и Ваша работа ориентирована
          на использование наших компакт-дисков, то мы готовы брать на себя
          замену пишушщего DVD-ROM устройства. Для наших клиентов поставка такого
          устройства - бесплатная.
        </li>
        <li>
          <span class="icon"> <i class="fas fa-circle"></i></span>
          Если у вас есть желание использовать диски с печатью на них вашего
          логотипа и другой нужной информации о вашей компании, то мы берем на
          себя как разработку макета, так и саму печать на самих дисках.
        </li>
      </ul>

      <div class="items"></div>
    </div>
  </div>
</template>



<style lang="scss" scoped>
h2 {
  color: white;
  text-align: center;
  font-size: 55px;
  margin-bottom: 55px;
}
h3 {
  color: white;
  text-align: left;
  font-size: 25px;
  margin-bottom: 55px;
  span {
    color: red;
    font-weight: bolder;
  }
}
ul {
  color: white;
  font-size: 26px;

  li{
    margin-bottom: 16px;
    line-height: 1.4em;
    list-style: none;
    position: relative;
    span{
      position: absolute;
      left: -40px;
      font-size: 12px;
      color: red;
    }
  }
  // li::before {
  //   content: "\f111";
  //   font-family: 'Font Awesome\ 5 Free';
  //   font-weight: 900;
    
  //   color: red;
  //   margin-left: -30px;
  //   position: relative;
  //   left: -30px;

  // }
}
p.note {
   font-size: 30px;
  color: white;
  margin-bottom: 60px;
  line-height: 1.6em;
  text-indent: 1.5em; /* Отступ первой строки */
  text-align: justify; /* Выравнивание по ширине */
}
.container-wrap {
  border-bottom: 8px solid #222;
}
.container {
  width: 1280px;
  margin: 0px auto;
  padding: 60px 0;
}

</style>



<script>
export default {
  name: "ClientsService",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>