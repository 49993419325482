<template>
  <section class="logo-presentation" id="logoPresentation">
    <div class="logo-wrap">
      <div class="logo-pict">
        <img src="/images/styles/logo-pic-part-white.svg" alt="" />
      </div>
      <div class="logo-text">
        <img src="/images/styles/logo-text-part.svg" alt="" />
      </div>
     <!--   <div class="req-input">
        <div>
          <input type="text" placeholder="Введите ваш телефон" />
        </div>
      <div>
          <button>ОТПРАВИТЬ. Мы вам перезвоним</button>
        </div>
      </div>-->
    </div>

    <!-- <div id="mouse">
      <div id="wheel"></div>
      <div class="scroll"></div>
      <div class="scroll2"></div>
      <div class="scroll3"></div>
    </div> -->
    <!-- <div id="text">SCROLL</div> -->
  </section>
<section>
  <Header/>
</section>
  <section class="brands_list">
    <BrandsList />
  </section>
  <section class="faqs">
     <p><a name="faq"></a></p>
    <Faqs />
  </section>
  <section class="sellers">
    <a name="sellers"></a>
    <Sellers />
  </section>
  
  <section class="ClientsService">
    <ClientsService />
  </section>
  
</template>

<script>
import BrandsList from "./components/sections/BrandsList.vue";
import Faqs from "./components/sections/Faqs.vue";
import Sellers from "./components/sections/Sellers.vue";
import ClientsService from "./components/sections/ClientsService.vue";
import Header from "./components/sections/Header.vue";
export default {
  name: "IndexComponent",
  data() {
    return {};
  },
  components: {
    BrandsList,
    Faqs,
    Sellers,
    ClientsService,
    Header
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.brands_list {
  display: block;
}
section {
  background: rgb(0, 0, 0);
  h1 {
    text-align: center;
    // margin: 30px auto;
    font-size: 3em;
  }
  background: rgb(255,0,0);
background: linear-gradient(90deg, rgba(88,0,0,1) 0%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 85%, rgba(88,0,0,1) 100%);
}

section.logo-presentation {
  // height: 650px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) 2%,
    rgba(255, 0, 0, 1) 50%,
    rgba(0, 0, 0, 1) 110%
  );

  height: 800px;

  div.logo-wrap {

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
    .logo-pict {
      text-align: center;
      img {     
        width: 200px;
        height: 200px;
      }
    }

    .logo-text {
      text-align: center;
      img {    
        width: 350px;
        height: auto;
      }
    }
    .req-input {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;

      input {
        padding: 15px 20px;
        width: 280px;
        text-align: left;
        border-color: white;
        background-color: white;
        border-style: solid;
      }

      button {
        padding: 15px 20px;
        color: white;
        background-color: black;
        cursor: pointer;
        border-color: black;
      }
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Lato:100);
body {
  background-color: #333;
}
#mouse {
  margin-top: 5em;
  margin-left: auto;
  margin-right: auto;
  width: 3.5em;
  height: 6em;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
  border: 2px solid white;

  position: absolute;
  left: 50%;
  bottom: 25px;
  margin-left: -30px;
}
#wheel {
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 0.75em;
  height: 0.75em;
  background-color: white;
  border-radius: 50%;
}
.scroll2 {
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  width: 0px !important;
  height: 0px;
  border-left: 0.35em solid transparent;
  border-right: 0.35em solid transparent;
  border-top: 0.4em solid white;
  -webkit-animation: down2 1s infinite;
  animation: down2 1s infinite;
}
.scroll3 {
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  width: 0px !important;
  height: 0px;
  border-left: 0.35em solid transparent;
  border-right: 0.35em solid transparent;
  border-top: 0.4em solid white;
  -webkit-animation: down 1s infinite;
  animation: down 1s infinite;
}
.scroll {
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  width: 0px !important;
  height: 0px;
  border-left: 0.35em solid transparent;
  border-right: 0.35em solid transparent;
  border-top: 0.4em solid white;
  -webkit-animation: down3 1s infinite;
  animation: down3 1s infinite;
}
@-webkit-keyframes down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(2.3em);
  }
}
@-webkit-keyframes down2 {
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
    transform: translateY(2.3em);
  }
}
@-webkit-keyframes down3 {
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
    transform: translateY(2.3em);
  }
}
@keyframes down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(2.3em);
  }
}
@keyframes down2 {
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
    transform: translateY(2.3em);
  }
}
@keyframes down3 {
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
    transform: translateY(2.3em);
  }
}
#text {
  font-family: "Lato";
  font-size: 1em;
  text-align: center;
  margin-top: 0.5em;
  color: white;
}
</style>
