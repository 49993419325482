<template>
  <div class="wrap">
    <div class="video">
      <video autoplay muted loop>
        <source src="/images/video/main_2.mp4" type="video/mp4" />
    </video>
      <!-- <img src="/images/video/video.png" alt=""> -->
    </div>
    <div class="content">
      <h1>Компакт-диски <br/>CD-r, DVD-r, DVD "+" r, miniCD<br/> для нужд предприятий и организаций</h1>
    </div>
  </div>
</template>



<style lang="scss" scoped>
.wrap {
  min-height: 500px;
  position: relative;
  .video {
    width: 100%;
    height: 500px;
    background-image: url('/images/video/video2.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
    text-align: center;
    display: flex;
    video {
      width: 100%;
    }
  }
.content {
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  left:0;
  top:0;
  bottom: 0;
  right:0;
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 50px;
    margin-top: -90px;
    margin-left: -455px;
  }
}
  
}
</style>



<script>
export default {
  name: "Faqs",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>